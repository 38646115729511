export function getCurrentDateTime() {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();
  let hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  const ampm = hours >= 12 ? "pm" : "am";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedDateTime = `${day}th ${month} ${year}, ${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")} ${ampm}`;

  return formattedDateTime;
}

export function removeCommasAndConvertToNumber(str) {
  if (typeof str === "string") {
    if (str && str?.includes(",")) {
      return parseFloat(str.replace(/,/g, ""));
    } else {
      return parseFloat(str);
    }
  } else {
    return parseFloat(str);
  }
}
