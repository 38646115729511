"use client";
//? Library Imports ----------------------------------------------------------->
import Cookies from "js-cookie";

//? Functions ------------------------------------------------------------------>
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000); // Calculate expiration time

  let expires = "expires=" + d.toUTCString(); // Convert expiration time to UTC string

  // Set the cookie with the provided name, value, expiration, and path
  document.cookie =
    cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/";
}

export function getCookieValue(cookieName) {
  // Split all the cookies into an array
  var cookies = document.cookie.split(";");

  // Iterate over each cookie
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();

    // Check if the cookie starts with the given name
    if (cookie.indexOf(cookieName + "=") === 0) {
      // Return the decoded cookie value (substring after the equals sign)
      return decodeURIComponent(cookie.substring(cookieName.length + 1));
    }
  }

  // Return null if the cookie is not found
  return null;
}

export function deleteCookie(name, path) {
  document.cookie =
    name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=" + path;
}

export function customerLogin(LoginResponse) {
  const expirationTimeInMinutes = 6 * 60;

  Cookies.set("loggedIn", true, {
    secure: true,
  });
  if (LoginResponse?.userType === "islamic") {
    Cookies.set("main_theme", "#007D84", {
      secure: true,
    });
    Cookies.set("secondary_theme", "#ffffff", {
      secure: true,
    });
    Cookies.set("user_type", "islamic", {
      expires: 365,
    });
  } else if (LoginResponse?.userType === "premier") {
    Cookies.set("main_theme", "rgb(223, 189, 92)", {
      secure: true,
    });
    Cookies.set("secondary_theme", "#000000", {
      secure: true,
    });
    Cookies.set("user_type", "premier", {
      expires: 365,
    });
  } else {
    Cookies.set("main_theme", "#d62314", {
      secure: true,
    });
    Cookies.set("secondary_theme", "#ffffff", {
      secure: true,
    });
    Cookies.set("user_type", "default", {
      expires: 365,
    });
    // Cookies.set("main_theme", "#d62314", {
    //   secure: true,
    // });
    // Cookies.set("secondary_theme", "#ffffff", {
    //   secure: true,
    // });
  }

  Cookies.set("comverse_customer_id", LoginResponse?.id, {
    // expires: expirationTimeInMinutes,
    secure: true,
  });
  Cookies.set("comverse_customer_email", LoginResponse?.email, {
    // expires: expirationTimeInMinutes,
    secure: true,
  });
  Cookies.set("comverse_customer_token", LoginResponse?.token, {
    // expires: expirationTimeInMinutes,
    secure: true,
  });
  Cookies.set("comverse_customer_name", LoginResponse?.full_name, {
    // expires: expirationTimeInMinutes,
    secure: true,
  });
}

export function customerLogout() {
  const cookiesCurrency = Cookies.get("defaultCurrencyName");
  const customerEmail = Cookies.get("comverse_customer_email");
  const customerId = Cookies.get("comverse_customer_id");
  if (customerEmail || customerId) {
    // window.Moengage.track_event("User Logout", {
    //   Email: customerEmail ? customerEmail : " Not Found",
    //   "Customer ID": customerId ? customerId : " Not Found",
    //   Source: "Website",
    //   Currency: cookiesCurrency ? cookiesCurrency : "PKR",
    // });
  }
  Cookies.remove("loggedIn");
  Cookies.remove("comverse_customer_id");
  Cookies.remove("comverse_customer_email");
  Cookies.remove("comverse_customer_token");
  Cookies.remove("comverse_customer_name");
  Cookies.remove("cartId");
  location.reload();
}

export default function AccountStatus() {
  const comverse_customer_token = Cookies.get("comverse_customer_token");

  // If the token is present, user is considered logged in
  if (comverse_customer_token) {
    return {
      loggedIn: true,
      comverse_customer_id: Cookies.get("comverse_customer_id"),
      comverse_customer_email: Cookies.get("comverse_customer_email"),
      comverse_customer_token: comverse_customer_token,
      comverse_customer_name: Cookies.get("comverse_customer_name"),
    };
  } else {
    return {
      loggedIn: false,
    };
  }
}
