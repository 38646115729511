import Link from "next/link";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Divider, Row, Space, Typography, Spin } from "antd";
import { usePathname, useRouter } from "next/navigation";
import SignupForm from "@/components/SignUpPage/Modules/SignupForm";
import { customerLogin, customerLogout } from "@/functions/Cookies/Cookies";
import { loginReducer } from "@/redux/Slices/accountSlice";
import { LoadingOutlined } from "@ant-design/icons";
import Loading from "@/app/loading";

import {
  BiArrowBack,
  BiLogoTiktok,
  BiLogoYoutube,
  BiLogoTwitter,
  BiLogoWhatsapp,
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoPinterestAlt,
} from "react-icons/bi";
import "./LoginMobile.scss";
import { BsSearch } from "react-icons/bs";
import { signIn, useSession, signOut } from "next-auth/react";
import AccountApi from "@/Apis/AccountApi";

import MobileServices from "./MobileServices";
import MobileLoginForm from "./MobileLoginForm";
import LoginSignup from "@/components/SignUpPage/LoginSignup";

const { Text } = Typography;

export default function LoginMobile() {
  const path = usePathname();
  const dispatch = useDispatch();
  const [showLoginModal, setShowLoginModal] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const { data } = useSession();
  const router = useRouter();
  const userSocialLogin = async () => {
    setLoadingSpinner(true);
    const dataFromApi = await AccountApi.socialLogin({
      full_name: data?.user?.name,
      email: data?.user?.email,
      source: "google",
    }).then((data) => {
      return data;
    });
    if (dataFromApi?.status === 200) {
      customerLogin(dataFromApi?.data);
      dispatch(loginReducer(dataFromApi?.data));
      // setLoadingSpinner(false);
      router.push("/");
    } else {
      setLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (data) {
      userSocialLogin(data);
    } else {
      setLoadingSpinner(false);
    }
  }, [data]);

  return loadingSpinner ? (
    <Loading />
  ) : (
    <Col className="login-profile">
      {/* Header */}

      {/* <div style={{ position: "relative" }}>
        <Row
          justify={"space-between"}
          align={"center"}
          className="profile-header"
        >
          <Col span={24} style={{ display: "block", textAlign: "center" }}>
            <Text style={{ fontSize: "16px", lineHeight: "25px" }}>
              Profile
            </Text>
          </Col>
        </Row>
        <div className="mobile-login-screen-back-div">
          <Link href="/">
            <BiArrowBack
              style={{ fontSize: "18px" }}
              className="cursorPointer"
            />
          </Link>
        </div>
      </div> */}
      {/* <Divider /> */}
      <div>
        <LoginSignup />
      </div>
      {/* Login Title */}
      {/* <Col span={24} className="second-header-login-mobile-screen">
        <Link className="mobile-login-screen-back-div" href="/">
          <BiArrowBack style={{ fontSize: "18px" }} className="cursorPointer" />
        </Link>
        {showLoginModal ? (
          <Text className="second-header-login-mobile-screen-text">Login</Text>
        ) : (
          <Text className="second-header-login-mobile-screen-text">
            Sign Up
          </Text>
        )}
      </Col> */}
      {/* <Divider /> */}
      {/* Login Form */}

      {/* <Col>
        {showLoginModal ? (
          <MobileLoginForm setShowLoginModal={setShowLoginModal} mobileScreen />
        ) : (
          <div className="paddingTop20">
            <SignupForm setShowLoginModal={setShowLoginModal} mobileScreen />
          </div>
        )}
      </Col> */}
      {/* Services */}
      {path !== "/login" && (
        <Col style={{ margin: "56px 10px 0px 10px" }}>
          <MobileServices />
        </Col>
      )}
      {/* Footer */}
      <Col
      // style={{ margin: "19px 114px 15px 114px" }}
      >
        <Row>
          {/* <Col
            span={24}
            style={{
              display: "block",
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <Text style={{ fontSize: "10px", lineHeight: "16px" }}>
              Get Updated
            </Text>
          </Col> */}

          {/* <Col span={24}>
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BiLogoFacebook style={{ fontSize: "18px" }} />
              <BiLogoTwitter style={{ fontSize: "18px" }} />
              <BiLogoInstagram style={{ fontSize: "18px" }} />
              <BiLogoTiktok style={{ fontSize: "18px" }} />
              <BiLogoPinterestAlt style={{ fontSize: "18px" }} />
              <BiLogoWhatsapp style={{ fontSize: "18px" }} />
              <BiLogoYoutube style={{ fontSize: "18px" }} />
            </Space>
          </Col> */}
        </Row>
      </Col>
    </Col>
  );
}
