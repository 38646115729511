import React from "react";
import PropTypes from "prop-types";
import "./CustomSkeletonLines.scss";

const CustomParagraphSkeleton = ({ singleHeading, width }) => {
  const styles = {
    heading: {
      height: "40px",
      marginBottom: "20px",
      backgroundColor: "#e0e0e0",
      borderRadius: "4px",
    },
    text: {
      height: "10px",
      marginBottom: "10px",
      backgroundColor: "#e0e0e0",
      borderRadius: "4px",
    },
  };

  return (
    <div
      className="custom-paragraph-skeleton"
      style={{ padding: "10px", width: `${width}%` }}
    >
      {singleHeading ? (
        <div style={styles.heading}></div>
      ) : (
        <>
          <div style={styles.heading}></div>
          <div style={styles.text}></div>
          <div style={styles.text}></div>
          <div style={styles.text}></div>
          <div style={styles.text}></div>
          <div style={styles.text}></div>
          <div style={styles.text}></div>
          <div style={styles.text}></div>
          <div style={styles.text}></div>
          <div style={styles.text}></div>
        </>
      )}
    </div>
  );
};

CustomParagraphSkeleton.propTypes = {
  width: PropTypes.string.isRequired,
};

export default CustomParagraphSkeleton;
