//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/navigation";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SignupForm from "../../CheckoutLogin/Modules/SignupForm";
import Cookies from "js-cookie";
import {
  Col,
  Row,
  Form,
  Space,
  Input,
  Button,
  message,
  Typography,
} from "antd";
import { getCurrentDateTime } from "../../../../functions/HelperFunctions/index";
import { signIn, useSession, signOut } from "next-auth/react";

//? Assets --------------------------------------------------------------------->
import "./MobileLoginForm.scss";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";

//? Redux Reducers ------------------------------------------------------------->
import usePath from "@/hooks/usePath/usePath";
import { loginReducer } from "@/redux/Slices/accountSlice";
import { customerLogin, customerLogout } from "@/functions/Cookies/Cookies";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function MobileLoginForm(props) {
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");
  //? Hooks ---------------------------------------------------------------------->
  const path = usePath();
  const router = useRouter();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  //? Functions ------------------------------------------------------------------>
  const onLogin = async (values) => {
    let currentDate = getCurrentDateTime();
    setLoading(true);
    try {
      const payload = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      const res = await fetch(
        process.env.NEXT_PUBLIC_BACKEND + `/storefront/signin?storefront=23`,
        payload
      );

      const LoginResponse = await res.json();
      if (!res.ok) {
        setLoading(false);
        throw new Error("Login Api Failed!");
      } else {
        if (res.ok) {
          message.success("Successfully logged in!");
          if (window.Moengage && window.Moengage.track_event) {
            window.Moengage.add_email(values?.email);
            Moengage.add_unique_user_id(values?.email);
            window.Moengage.add_first_name(LoginResponse?.full_name);
            window.Moengage.add_last_name(LoginResponse?.full_name);
            window.Moengage.add_user_name(LoginResponse?.full_name);
            LoginResponse?.phone &&
              window.Moengage.add_mobile(LoginResponse?.phone);
            window.Moengage.track_event("Customer Logged In", {
              "Event Received Time": currentDate,
              Email: values?.email,
              Phone: LoginResponse?.phone,
              FirstName: LoginResponse?.full_name,
              LastName: LoginResponse?.full_name,
              "Customer ID": LoginResponse?.id,
              Source: "Website",
              Currency: defaultCurrencyFromCookies
                ? defaultCurrencyFromCookies
                : "PKR",
            });
          }
          if (process.env.NEXT_PUBLIC_ENABLE_SCRIPTS === "true") {
            if (typeof window !== "undefined" && window?.dataLayer) {
              dataLayer.push({ ecommerce: null });
              dataLayer.push({
                event: "login",
                user_id: LoginResponse?.id,
              });
            }
          }
        }
        setLoading(false);
        customerLogin(LoginResponse);
        dispatch(loginReducer(LoginResponse));
        // setShowLoginModal(false); TODO: abdullah-dev removed this

        if (path !== "/cart/checkout") {
          router.push("/");
        }
        return LoginResponse;
      }
    } catch (error) {
      setLoading(false);
      message.error("Invalid Email or Password");
      console.error("Unable to Login:", error.message);
      return null;
    }
  };

  const onLoginFailed = (errorInfo) => {
    customerLogout();
    message.error(errorInfo);
  };

  return (
    <>
      <Col className="mobile-login">
        <Form
          name="Mobile-Login-Form"
          layout="vertical"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 533 }}
          initialValues={{
            remember: true,
          }}
          autoComplete="on"
          onFinish={onLogin}
          onFinishFailed={onLoginFailed}
        >
          <Col style={{ marginBottom: "7px" }}>
            <Text className="form-label">Email</Text>
          </Col>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please Enter a Valid Email",
              },
            ]}
          >
            <Input style={{ height: "41px" }} placeholder="Email" />
          </Form.Item>
          <Col style={{ marginBottom: "7px" }}>
            <Row justify={"space-between"}>
              <Text className="form-label">Password</Text>
              <Link href="/account/forgotpassword">
                <Text style={{ fontSize: "14px", lineHeight: "21px" }}>
                  Forgot Your Password?
                </Text>
              </Link>
            </Row>
          </Col>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please Enter your Password",
              },
            ]}
          >
            <Input.Password style={{ height: "41px" }} placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-btn svg-button-wrapper"
            >
              {!loading ? (
                <Typography className="login-button-text-mobile">
                  Login
                </Typography>
              ) : (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                      }}
                      spin
                    />
                  }
                />
              )}
            </Button>
          </Form.Item>
        </Form>
        <Row
          justify={"center"}
          align={"center"}
          gutter={[0, 7]}
          className="modalfooter"
        >
          {/* <Col span={24} align="middle">
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                lineHeight: "18px",
              }}
            >
              Or Login With
            </Text>
          </Col> */}
          {/* <Col span={24} align="middle">
            <Row justify="space-evenly">
              <Col span={11}>
                <Button
                  icon={
                    <AiOutlineGoogle
                      style={{ fontSize: "18px", color: "#cf4332" }}
                    />
                  }
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    borderRadius: "6px",
                    fontSize: "12px",
                    justifyContent: "center",
                    color: "black"
                  }}
                  onClick={() => {
                    signIn("google");
                  }}
                >
                  <Typography style={{
                    alignSelf: "center",
                    paddingTop: "3px"
                  }}>
                    Google
                  </Typography>
                </Button>
              </Col>
              <Col span={11}>
                <Button
                  icon={
                    <FaFacebookF
                      style={{ fontSize: "18px", color: "#337fff" }}
                    />
                  }
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    borderRadius: "6px",
                    fontSize: "12px",
                    justifyContent: "center",
                    color: "black"
                  }}
                  onClick={() => {
                    signIn("facebook");
                  }}
                >
                  <Typography style={{
                    alignSelf: "center",
                    paddingTop: "3px"
                  }}>
                    Facebook
                  </Typography>
                </Button>
              </Col>
            </Row>
          </Col> */}
          <Col
            span={24}
            align="middle"
            style={{ marginTop: "20px", marginBottom: "10px" }}
          >
            <Text
              style={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "600",
              }}
            >
              Create an Account at &nbsp;
              <Text
                style={{
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginLeft: "-2px",
                  color: "var(--background-color)"
                }}
                onClick={() => {
                  props.setShowLoginModal(false);
                }}
              >
                Signup
              </Text>
            </Text>
          </Col>
        </Row>
      </Col>
    </>
  );
}
