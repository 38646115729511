import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "accountSlice",
  initialState: {
    // loggedIn: false,
    // comverse_customer_id: null,
    comverse_customer_email: null,
    // comverse_customer_token: null,
    comverse_customer_name: null,
    comverse_customer_addresses: [],
    comverse_customer_tags: [],
    activeMenu: "dashboard",
  },
  reducers: {
    loginReducer: (state, action) => {
      // state.loggedIn = true;
      // state.comverse_customer_id = action.payload.id;
      // state.comverse_customer_email = action.payload.email;
      // state.comverse_customer_token = action.payload.token;
      // sessionStorage.setItem("comverse_customer_id", action.payload.id);
      // sessionStorage.setItem("comverse_customer_email", action.payload.email);
      // sessionStorage.setItem("comverse_customer_token", action.payload.token);
      state.comverse_customer_email = action.payload.email;
      state.comverse_customer_tags = action.payload.tags;
      state.comverse_customer_name = action.payload.full_name;
      state.comverse_customer_addresses = action.payload.addresses;
    },
    logoutReducer: (state) => {
      // state.loggedIn = false;
      // state.comverse_customer_id = null;
      // state.comverse_customer_email = null;
      // state.comverse_customer_token = null;
      // sessionStorage.removeItem("comverse_customer_id");
      // sessionStorage.removeItem("comverse_customer_token");
      // sessionStorage.removeItem("comverse_customer_email");
      state.comverse_customer_email = null;
      state.comverse_customer_tags = [];
      state.comverse_customer_name = null;
      state.comverse_customer_addresses = [];
    },
    saveAddressReducer: (state, action) => {
      state.comverse_customer_addresses = action.payload;
    },
    changeActiveMenuReducer: (state, action) => {
     
      state.activeMenu = action.payload;
    },
    updateAccountInfoReducer: (state, action) => {
      state.comverse_customer_email = action.payload.email;
      state.comverse_customer_name = action.payload.full_name;
    },
  },
});

export const {
  loginReducer,
  logoutReducer,
  saveAddressReducer,
  changeActiveMenuReducer,
  updateAccountInfoReducer,
} = accountSlice.actions;
export default accountSlice.reducer;

// "use server";
// import { cookies } from "next/headers";
// import { createSlice } from "@reduxjs/toolkit";

// export const accountSlice = createSlice({
//   name: "accountSlice",
//   initialState: {
//     // loggedIn: false,
//     // comverse_customer_id: null,
//     // comverse_customer_name: null,
//     // comverse_customer_email: null,
//     // comverse_customer_token: null,
//     comverse_customer_tags: [],
//     comverse_customer_addresses: [],

//     loggedIn: cookies().get("loggedIn") ? cookies().get("loggedIn") : false,
//     comverse_customer_id: cookies().get("comverse_customer_id")
//       ? cookies().get("comverse_customer_id")
//       : null,
//     comverse_customer_name: cookies().get("comverse_customer_name")
//       ? cookies().get("comverse_customer_name")
//       : null,
//     comverse_customer_email: cookies().get("comverse_customer_email")
//       ? cookies().get("comverse_customer_email")
//       : null,
//     comverse_customer_token: cookies().get("comverse_customer_token")
//       ? cookies().get("comverse_customer_token")
//       : null,

//     // loggedIn: sessionStorage.getItem("comverse_customer_token") ? true : false,
//     // comverse_customer_id: sessionStorage.getItem("comverse_customer_id")
//     //   ? sessionStorage.getItem("comverse_customer_id")
//     //   : null,
//     // comverse_customer_email: sessionStorage.getItem("comverse_customer_email")
//     //   ? sessionStorage.getItem("comverse_customer_email")
//     //   : null,
//     // comverse_customer_token: sessionStorage.getItem("comverse_customer_token")
//     //   ? sessionStorage.getItem("comverse_customer_token")
//     //   : null,
//     // comverse_customer_name: sessionStorage.getItem("comverse_customer_name")
//     //   ? sessionStorage.getItem("comverse_customer_name")
//     //   : null,
//   },
//   reducers: {
//     loginReducer: (state, action) => {
//
//       state.loggedIn = true;
//       state.comverse_customer_id = action.payload.id;
//       state.comverse_customer_email = action.payload.email;
//       state.comverse_customer_token = action.payload.token;
//       // sessionStorage.setItem("comverse_customer_id", action.payload.id);
//       // sessionStorage.setItem("comverse_customer_email", action.payload.email);
//       // sessionStorage.setItem("comverse_customer_token", action.payload.token);

//       cookies().set("comverse_customer_id", action.payload.id, {
//         secure: true,
//       });
//       cookies().set("comverse_customer_email", action.payload.email, {
//         secure: true,
//       });
//       cookies().set("comverse_customer_token", action.payload.token, {
//         secure: true,
//       });

//       state.comverse_customer_tags = action.payload.tags;
//       state.comverse_customer_name = action.payload.full_name;
//       state.comverse_customer_addresses = action.payload.addresses;
//     },
//     logoutReducer: (state) => {
//
//       state.loggedIn = false;
//       state.comverse_customer_id = null;
//       state.comverse_customer_email = null;
//       state.comverse_customer_token = null;
//       sessionStorage.removeItem("comverse_customer_id");
//       sessionStorage.removeItem("comverse_customer_token");
//       sessionStorage.removeItem("comverse_customer_email");

//       state.comverse_customer_tags = [];
//       state.comverse_customer_name = null;
//       state.comverse_customer_addresses = [];
//     },
//     saveAddressReducer: (state, action) => {
//
//       state.comverse_customer_addresses = action.payload;
//     },
//   },
// });

// export const { loginReducer, logoutReducer, saveAddressReducer } =
//   accountSlice.actions;
// export default accountSlice.reducer;
