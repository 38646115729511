import React from "react";
import Link from "next/link";
import "./MobileServices.scss";
import { Col, Divider, Row, Typography } from "antd";
import { CiLocationOn } from "react-icons/ci";
import {
  MdOutlineDescription,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { AiOutlineGift, AiOutlineHeart } from "react-icons/ai";
import { RiShieldKeyholeLine } from "react-icons/ri";
import TrackOrderModal from "@/components/Homepage/TrackOrderModal/TrackOrderModal";
const { Text } = Typography;
export default function MobileServices() {
  const [OpenOrderTrackingModal, setOpenOrderTrackingModal] = useState(false);
  return (
    <Col className="mobile-services">
      <Row>
        <Col span={24}>
          {/* <Link href="#"> */}
          {/* <Row justify="space-between" align="center" className="service"> */}
          {/* <Col span={2}>
                <CiLocationOn className="service-icon" />
              </Col>
              <Col span={20}>
                <a
                  target="_blank"
                  href="https://oms.alfamall.com/ordertracking/021024025744950817"
                  className="service-text"
                >
                  Track Order
                </a>
              </Col> */}
          {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
          {/* </Row> */}
          {/* </Link> */}
          <button
            style={{
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              border: "none",
              background: "none",
              padding: 0,
              margin: 0,
            }}
            onClick={() => setOpenOrderTrackingModal(true)}
          >
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <CiLocationOn className="service-icon" />
              </Col>
              <Col span={20}>
                <Text
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Track Order
                </Text>
              </Col>
            </Row>
          </button>
        </Col>
        {/* <Divider/>
        <Col span={24}>
            <Row justify='space-between' align='center' className='service'>
                <Col span={2}><AiOutlineHeart className='service-icon'/></Col>
                <Col span={20}><Text className='service-text'>WishtList</Text></Col>
                <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon'/></Col>
            </Row>
        </Col>
        <Divider/>
        <Col span={24}>
            <Row justify='space-between' align='center' className='service'>
                <Col span={2}><AiOutlineGift className='service-icon'/></Col>
                <Col span={20}><Text className='service-text'>Reward Point</Text></Col>
                <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon'/></Col>
            </Row>
        </Col> */}
        <Divider />

        <Col span={24}>
          <Link href={"#"}>
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <CiLocationOn className="service-icon" />
              </Col>
              <Col span={20}>
                <Text className="service-text">FAQs</Text>
              </Col>
              {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
            </Row>
          </Link>
        </Col>

        <Divider />
        <Col span={24}>
          <Link href={"/about-us"}>
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <CiLocationOn className="service-icon" />
              </Col>
              <Col span={20}>
                <Text className="service-text">About Us</Text>
              </Col>
              {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
            </Row>
          </Link>
        </Col>
        <Divider />
        <Col span={24}>
          <Link href={"/pages/static/terms-of-service"}>
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <MdOutlineDescription className="service-icon" />
              </Col>
              <Col span={20}>
                <Text className="service-text">Terms & Conditions</Text>
              </Col>
              {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
            </Row>
          </Link>
        </Col>
        <Divider />
        <Col span={24}>
          <Link href={"/pages/static/privacy-policy"}>
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <RiShieldKeyholeLine className="service-icon" />
              </Col>
              <Col span={20}>
                <Text className="service-text">Privacy Policy</Text>
              </Col>
              {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
            </Row>
          </Link>
        </Col>
      </Row>
      {OpenOrderTrackingModal && (
        <TrackOrderModal
        showTrackOrderModal={setOpenOrderTrackingModal}
          openTrackOrderModal={OpenOrderTrackingModal}
        />
      )}
    </Col>
  );
}
