import React from "react";
import PropTypes from "prop-types";
import "./CustomSkeletonBox.scss";

const CustomSkeletonBox = ({ width, height }) => {
  const boxStyles = {
    width: `${width}`,
    height: `${height}px`,
    margin: "auto",
  };

  return (
    <div className="custom-skeleton-box" style={boxStyles}>
      <div className="animated-layer"></div>
    </div>
  );
};

CustomSkeletonBox.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default CustomSkeletonBox;
